<!--  -->
<template>
  <div id="particles">
    <form action="" method="post" id="form">
      <div class="login">
        <img class="logo_image" v-if="logoImg" :src="logoImg" alt="" />
        <div class="login-top">
          {{ systemName }}
        </div>
        <div class="login-center clearfix">
          <div class="login-center-img"><img src="@/assets/images/login/name.png" /></div>
          <div class="login-center-input">
            <input
              v-model="loginData.phone"
              type="text"
              name="username"
              value=""
              placeholder="请输入您的手机号"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='请输入您的手机号'"
            />
            <div class="login-center-input-text">手机号</div>
          </div>
        </div>
        <div class="login-center clearfix">
          <div class="login-center-img"><img src="@/assets/images/login/password.png" /></div>
          <div class="login-center-input">
            <input
              v-model="loginData.password"
              type="password"
              name="password"
              value=""
              placeholder="请输入您的密码"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='请输入您的密码'"
            />
            <div class="login-center-input-text">密码</div>
          </div>
        </div>
        <div class="login-center clearfix">
          <div class="login-center-input" style="width: 120px; height: 40px; margin-left: 0px;">
            <input
              v-model="loginData.captcha"
              type="text"
              name="code"
              value=""
              placeholder="请输入验证码"
              onfocus="this.placeholder=''"
              onblur="this.placeholder='验证码'"
              style="width: 120px; height: 40px;"
            />
            <div class="login-center-input-text">验证码</div>
          </div>
          <div class="login-center-img" style="height: 40px; width: 130px; margin-top: 0px; float: right; cursor: pointer;">
            <img :src="captchaImg" @click="updateCaptcha" />
          </div>
        </div>
        <div class="login-button" @click="hanldeLogin">
          登录
        </div>
        <div class="third-login-button" @click="hanldeALiLogin">
          阿里巴巴授权登录
        </div>
      </div>
      <div class="sk-rotating-plane"></div>
    </form>
  </div>
</template>

<script>
import { baseApi } from '@/config'
import { generateUUID } from '../../../utils/util'
import { login } from '@/api/LoginController'
import { aLiBabaAuth } from '@/api/AlibabaController'
import { getCompanyImg } from '@/api/systemController'
export default {
  data() {
    return {
      captchaImg: '',
      loginData: {
        phone: '',
        password: '',
        captcha: '',
        uuid: ''
      },
      logoImg: '',
      systemName: ''
    }
  },
  mounted() {
    this.updateCaptcha()
    particlesJS.load('particles', '/static/json/particles.json')
    this.getSystemInfo()
  },
  methods: {
    hanldeALiLogin() {
      // console.log(location.origin)
      window.open(aLiBabaAuth({ route: `${location.origin}` }))
    },
    async getSystemInfo() {
      let [err, res] = await getCompanyImg()
      if (err || !res.data) {
        console.log(err)
        return this.$message.error(err.msg || '获取失败')
      }
      // console.log(res)
      this.logoImg = res.data.logo
      this.systemName = res.data.systemName
    },
    async hanldeLogin() {
      // console.log(this.loginData)
      let [err, res] = await login(this.loginData)
      if (err) {
        console.log('err:', err)
        this.updateCaptcha()
        return this.$message.error(err.msg)
      }
      console.log(res)
      this.$store.commit('Login/saveLogin', res.data)
      this.$router.push({
        path: '/userList'
      })
    },
    updateCaptcha() {
      this.loginData.uuid = generateUUID()
      this.captchaImg = `${baseApi}/captcha.jpg?uuid=${this.loginData.uuid}`
    }
  },

  components: {}
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
@import url('./index.less');
</style>
