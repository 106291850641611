import request from '@/utils/http'
import { awaitWrap } from '@/utils/util'
import { baseApi } from '@/config'

/**
 * 阿里巴巴授权
 * @param {*} route 页面路由 location.href
 */
export const aLiBabaAuth = ({ route }) => {
  // let query = ''
  // for (let key in params) {
  //   query += `${key}=${params[key]}&`
  // }
  // query = query.slice(0, query.length - 1)
  console.log(`${baseApi}/alibaba/gotoAuth?${route}`)
  return `${baseApi}/alibaba/gotoAuth?route=${route}`
}

/**
 * 登录
 * @param {*} "phone"
 * @param {*} "password"
 * @param {*} "captcha" 验证码
 * @param {*} "uuid"
 * @returns
 */
export const login = ({ ...data }) => {
  return awaitWrap(
    request({
      url: `/pc/login`,
      method: 'POST',
      data
    })
  )
}

/**
 * 登出
 * @returns
 */
export const logout = () => {
  return awaitWrap(
    request({
      url: `/pc/sys/logout`,
      method: 'POST'
    })
  )
}
